import { Button, ModalProps, ModalType } from "../../../Common";
import { Item } from "../../../../services/types";

type DeleteItemModalProps = {
	item: Item;
	onCloseModal: ModalProps["onCloseModal"];
};

function DeleteItemModal({ item, onCloseModal }: DeleteItemModalProps) {
	const handleOnClickDeleteItem = async () => {
		if (!item) return;

		onCloseModal({ type: ModalType.DELETE_ITEM, data: item });
	};

	return (
		<div className="flex flex-1 flex-col">
			<p
				className="
          text-xl
          font-bold
          leading-9
          text-center
          text-black
          tracking-tight
        "
			>
				<span>Are you sure, you want to delete: </span>
				<strong className="text-green-600">{item.name} </strong>
				<span>from your QR?</span>
			</p>

			<div
				className="
          mt-5
          space-y-2
          sm:flex
          sm:space-x-2
          sm:space-y-0
          sm:space-x-reverse
          sm:flex-row-reverse
      "
			>
				<Button
					id="add-qr-modal-button"
					onClick={handleOnClickDeleteItem}
					className="
            flex
            w-full
            border-2
            leading-6
            shadow-sm
            rounded-md
            text-white
            font-semibold
            justify-center
            bg-green-600
            border-green-600
            hover:bg-green-500
          "
				>
					Delete Item
				</Button>
				<Button
					id="cancel-qr-modal-button"
					onClick={() => onCloseModal({ type: "", data: null })}
					className="
            flex
            w-full
            border-2
            leading-6
            shadow-sm
            rounded-md
            bg-white
            font-semibold
            justify-center
            text-green-600
            border-green-600
            hover:text-white
            hover:bg-green-500
          "
				>
					Cancel
				</Button>
			</div>
		</div>
	);
}

export default DeleteItemModal;
