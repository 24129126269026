import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

import { Form, Button } from "../Common";
import { Checkbox } from "../Common/Inputs";

import { Brand } from "../Icons";

import { Api } from "../../services";

export type ResetPasswordConfirmInputs = {
  uid: string;
  token: string;
  new_password1: string;
  new_password2: string;
};

function ResetPasswordConfirm() {
  const formId = "reset-password-confirm-form";

  const navigate = useNavigate();
  const { id, token } = useParams();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [success, setSuccess] = useState<{ msg: string }>({ msg: "" });
  const [error, setError] = useState<{ msg: string; generic?: boolean }>({
    msg: "",
  });

  const { watch, resetField, handleSubmit, control, formState } =
    useForm<ResetPasswordConfirmInputs>({
      mode: "onChange",
      defaultValues: {
        uid: id || "",
        token: token || "",
        new_password1: "",
        new_password2: "",
      },
    });

  const watchForm = watch(["uid", "new_password1"]);

  useEffect(() => {
    if (!id || !token) navigate("/");

    resetField("uid", { defaultValue: id });
    resetField("token", { defaultValue: token });
  }, [id, token, navigate, resetField]);

  const onSubmit: SubmitHandler<ResetPasswordConfirmInputs> = async (form) => {
    setError({ msg: "" });
    setSuccess({ msg: "" });
    try {
      const response = await Api.resetPasswordConfirm(form);
      if (response.ok)
        setSuccess({
          msg: "Your password was successfuly reset.",
        });
      else setError({ msg: response.error! });
    } catch (e) {
      const error = e as Error;
      setError({ msg: error.message, generic: true });
    }
  };

  const handleShowPassword = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setShowPassword(!event.target.defaultChecked),
    []
  );

  const disabledSubmitButton =
    !formState.isValid && "opacity-25 cursor-not-allowed pointer-events-none";

  return (
    <div
      className="
        px-6
        flex
        py-12
        flex-col
        justify-center
        lg:px-8
      "
    >
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="w-20 mx-auto">
          <Brand size={80} color="fill-green-600" />
        </div>
        <h2
          className="
            mt-5
            text-2xl
            font-bold
            leading-9
            text-center
            text-black
            tracking-tight
          "
        >
          Reset password to your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <Form.Text
            required={true}
            label="password"
            control={control}
            name="new_password1"
            id="new_password1-input"
            errors={formState.errors}
            type={showPassword ? "text" : "password"}
            rules={{
              required: { value: true, message: "Password is required." },
              validate: {
                lenght: (v: string) =>
                  v.length >= 8 || "Password should be at least 8 characters",
                number: (v: string) =>
                  /\d/gm.test(v) || "Password should have at least 1 number.",
                letter: (v: string) =>
                  /\D/gm.test(v) || "Password should have at least 1 letter.",
              },
            }}
          />
          <Form.ErrorMessage errors={formState.errors} name="new_password1" />

          <Form.Text
            required={true}
            control={control}
            name="new_password2"
            id="new_password2-input"
            label="verifiy password"
            errors={formState.errors}
            type={showPassword ? "text" : "password"}
            rules={{
              required: { value: true, message: "Password is required." },
              validate: {
                lenght: (v: string) =>
                  v.length >= 8 || "Password should be at least 8 characters",
                number: (v: string) =>
                  /\d/gm.test(v) || "Password should have at least 1 number.",
                letter: (v: string) =>
                  /\D/gm.test(v) || "Password should have at least 1 letter.",
                verify: (v: string) =>
                  v === watchForm[1] || "Password should be the same.",
              },
            }}
          />
          <Form.ErrorMessage errors={formState.errors} name="new_password2" />

          <div>
            <div className="flex flex-row items-center">
              <Checkbox
                label="Show password"
                checked={showPassword}
                id="show-password-checkbox"
                onChange={handleShowPassword}
              />
            </div>
          </div>

          <div className="mt-1 text-sm text-center font-semibold">
            {success && <p>{success.msg}</p>}
            {error && (
              <div className="text-red-600">
                {error.generic && (
                  <p>Something went wrong, please try again later!</p>
                )}
                <p>{error.msg}</p>
              </div>
            )}
          </div>

          <Button
            form={formId}
            type="submit"
            value="submit"
            id="login-submit-button"
            disabled={!formState.isValid}
            className={`
              flex
              mt-10
              w-full
              border-2
              leading-6
              shadow-sm
              rounded-md
              text-white
              font-semibold
              justify-center
              bg-green-600
              border-green-600
              hover:bg-green-500
              ${disabledSubmitButton}
            `}
          >
            Reset Password
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default ResetPasswordConfirm;
