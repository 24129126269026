import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { trackPromise } from "react-promise-tracker";

import {
	Methods,
	CustomHeaderProperties,
	// API INPUTS
	LoginInputs,
	RegisterInputs,
	PatchUserInputs,
	ItemReportInputs,
	PatchQrUserInputs,
	LoginGoogleInputs,
	VerifyEmailInputs,
	LoginFacebookInputs,
	ResetPasswordInputs,
	ResendVerifyEmailInputs,
	ResetPasswordConfirmInputs,
	ManageQrUserItemsInputs,
	// API RESPONSES
	QrAPIResponse,
	UserAPIResponse,
	BasicAPIResponse,
	QrUserAPIResponse,
	UserItemsAPIResponse,
	VerifyAuthAPIResponse,
	CategoriesAPIResponse,
	QrListUserAPIResponse,
} from "./types";

import StorePersist from "./StorePersist";

type ConfigProps = {
	token: string;
};

class LFApi {
	token: string;
	baseUrl: string;
	apiV1Url: string;
	authUrl: string;
	loginUrl: string;
	signinUrl: string;
	logoutUrl: string;
	verifyUrl: string;
	verifyEmailUrl: string;
	resetPasswordlUrl: string;
	changePasswordlUrl: string;
	resendVerifyEmailUrl: string;
	resetPasswordlConfirmUrl: string;

	constructor() {
		this.token = "";
		this.baseUrl = (window as any).SERVER_DATA.REACT_APP_BACKEND_URL;

		this.apiV1Url = `${this.baseUrl}/api/v1`;
		this.authUrl = `${this.apiV1Url}/auth`;

		//Auth endpoints
		this.loginUrl = `${this.authUrl}/login/`;
		this.verifyUrl = `${this.authUrl}/verify/`;
		this.logoutUrl = `${this.authUrl}/logout/`;
		this.signinUrl = `${this.authUrl}/register/`;
		this.verifyEmailUrl = `${this.authUrl}/verify-email/`;
		this.resetPasswordlUrl = `${this.authUrl}/password-reset/`;
		this.resendVerifyEmailUrl = `${this.authUrl}/resend-email/`;
		this.changePasswordlUrl = `${this.authUrl}/password-change/`;
		this.resetPasswordlConfirmUrl = `${this.authUrl}/password-reset-confirm/`;
	}

	configure({ token }: ConfigProps) {
		this.token = token;
	}

	getHeaders(): CustomHeaderProperties {
		return {
			Authorization: `Bearer ${this.token}`,
		} as CustomHeaderProperties;
	}

	privateRequest({
		...props
	}: Omit<AxiosRequestConfig, "method"> & { method: Methods }) {
		return axios.request({
			...props,
			headers: this.getHeaders(),
			method: props.method.toString(),
		});
	}

	// PRIVATE REQUESTS

	async verify(): Promise<VerifyAuthAPIResponse> {
		try {
			await this.privateRequest({
				method: Methods.OPTIONS,
				url: this.verifyUrl,
			});
			return { ok: true, isAuthenticated: true };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail } = error.response?.data;
				return { error: detail };
			} else throw error;
		}
	}

	async getUser(): Promise<UserAPIResponse> {
		const userRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				method: Methods.GET,
				url: `${this.authUrl}/user/`,
			})
		);

		try {
			const { data } = await userRequest;
			return { user: data };
		} catch (error) {
			throw error;
		}
	}

	async patchUser(patchUserInputs: PatchUserInputs): Promise<UserAPIResponse> {
		const qrUserRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				data: patchUserInputs,
				method: Methods.PATCH,
				url: `${this.authUrl}/user/`,
			})
		);

		try {
			const { data } = await qrUserRequest;

			return { ok: data.ok, user: data.user };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}

	async patchQrUser({ code, user }: PatchQrUserInputs): Promise<QrAPIResponse> {
		const qrUserRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				data: { user },
				method: Methods.PATCH,
				url: `${this.apiV1Url}/qr/${code}/`,
			})
		);

		try {
			const { data } = await qrUserRequest;

			return { ok: data.ok, qr: data.qr };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}

	async getQrListUser(): Promise<QrListUserAPIResponse> {
		const qrUserRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				method: Methods.GET,
				url: `${this.apiV1Url}/qr/list/`,
			})
		);

		try {
			const { data } = await qrUserRequest;

			return { ok: true, qrs: data };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}

	async getQrUser(code: string): Promise<QrUserAPIResponse> {
		const qrUserRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				method: Methods.GET,
				url: `${this.apiV1Url}/qr/${code}/items/`,
			})
		);

		try {
			const { data } = await qrUserRequest;

			return { ok: true, qr: data.qr };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}

	async postQrUserItems({
		items,
		qrCode,
	}: ManageQrUserItemsInputs): Promise<UserItemsAPIResponse> {
		const createItemRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				data: { items },
				method: Methods.POST,
				url: `${this.apiV1Url}/items/${qrCode}/`,
			})
		);

		try {
			const { data } = await createItemRequest;

			return { ok: data.ok, items: data.items };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}

	async deleteQrUserItems({
		items,
		qrCode,
	}: ManageQrUserItemsInputs): Promise<UserItemsAPIResponse> {
		const createItemRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				method: Methods.DELETE,
				url: `${this.apiV1Url}/items/${qrCode}/`,
				data: { items: items.map((item) => item.id) },
			})
		);

		try {
			const { data } = await createItemRequest;

			return { ok: data.ok, items: data.items };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}

	// PUBLIC REQUESTS

	async register(registerObject: RegisterInputs): Promise<BasicAPIResponse> {
		const registerRequest = trackPromise<AxiosResponse>(
			axios.post(this.signinUrl, registerObject)
		);

		try {
			await registerRequest;
			return { ok: true };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { email, password1, password2, non_field_errors } =
					error.response?.data;
				return {
					error: email || password1 || password2 || non_field_errors[0],
				};
			} else throw error;
		}
	}

	async verifyEmail(
		verifyObject: VerifyEmailInputs
	): Promise<BasicAPIResponse> {
		try {
			await axios.post(this.verifyEmailUrl, verifyObject);
			return { ok: true };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail } = error.response?.data;
				return { error: detail };
			} else throw error;
		}
	}

	async resendVerifyEmail(
		resendObject: ResendVerifyEmailInputs
	): Promise<BasicAPIResponse> {
		const resendVerifyEmailRequest = trackPromise<AxiosResponse>(
			axios.post(this.resendVerifyEmailUrl, resendObject)
		);

		try {
			await resendVerifyEmailRequest;
			return { ok: true };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { email, non_field_errors } = error.response?.data;
				return { error: email || non_field_errors[0] };
			} else throw error;
		}
	}

	async login(loginObject: LoginInputs): Promise<BasicAPIResponse> {
		const loginRequest = trackPromise<AxiosResponse>(
			axios.post(this.loginUrl, loginObject)
		);

		try {
			const loginResponse = await loginRequest;
			const { access_token } = loginResponse.data;

			this.configure({ token: access_token });
			const { user } = await this.getUser();

			StorePersist.saveState(
				{ user, token: access_token },
				StorePersist.Items.AUTH_STATE
			);

			return { ok: true };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { email, non_field_errors } = error.response?.data;
				return { error: email || non_field_errors[0] };
			} else throw error;
		}
	}

	async loginGoogle(
		loginGoogleObject: LoginGoogleInputs
	): Promise<BasicAPIResponse> {
		const loginGoogleRequest = trackPromise<AxiosResponse>(
			axios.post(`${this.authUrl}/google/`, loginGoogleObject)
		);

		try {
			const loginGoogleResponse = await loginGoogleRequest;
			const { access_token } = loginGoogleResponse.data;

			this.configure({ token: access_token });
			const { user } = await this.getUser();

			StorePersist.saveState(
				{ user, token: access_token },
				StorePersist.Items.AUTH_STATE
			);

			return { ok: true };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { response } = error;
				const errorMsg = response?.data.non_field_errors
					? response?.data.non_field_errors[0]
					: null;

				return { error: `Google: ${errorMsg || response?.statusText}` };
			} else throw error;
		}
	}

	async loginFacebook(
		loginFacebookObject: LoginFacebookInputs
	): Promise<BasicAPIResponse> {
		const loginFacebookRequest = trackPromise<AxiosResponse>(
			axios.post(`${this.authUrl}/facebook/`, {
				access_token: loginFacebookObject.accessToken,
			})
		);

		try {
			const loginFacebookResponse = await loginFacebookRequest;
			const { access_token } = loginFacebookResponse.data;

			this.configure({ token: access_token });
			const { user } = await this.getUser();

			StorePersist.saveState(
				{ user, token: access_token },
				StorePersist.Items.AUTH_STATE
			);

			return { ok: true };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { response } = error;
				const errorMsg = response?.data.non_field_errors
					? response?.data.non_field_errors[0]
					: null;

				return { error: `Facebook: ${errorMsg || response?.statusText}` };
			} else throw error;
		}
	}

	async resetPassword(
		resetPasswordObject: ResetPasswordInputs
	): Promise<BasicAPIResponse> {
		const resetPasswordRequest = trackPromise<AxiosResponse>(
			axios.post(this.resetPasswordlUrl, resetPasswordObject)
		);

		try {
			await resetPasswordRequest;
			return { ok: true };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { email, non_field_errors } = error.response?.data;
				return { error: email || non_field_errors[0] };
			} else throw error;
		}
	}

	async resetPasswordConfirm(
		resetPasswordConfirmObject: ResetPasswordConfirmInputs
	): Promise<BasicAPIResponse> {
		const resetPasswordConfirmRequest = trackPromise<AxiosResponse>(
			axios.post(this.resetPasswordlConfirmUrl, resetPasswordConfirmObject)
		);

		try {
			const { data } = await resetPasswordConfirmRequest;

			return { ok: true, msg: data.detail };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { uid, token, new_password1, new_password2, non_field_errors } =
					error.response?.data;

				const processUid = uid ? `ID: ${uid}` : null;
				const processToken = token ? `Token: ${token}` : null;
				return {
					error:
						processUid ||
						processToken ||
						new_password1 ||
						new_password2 ||
						non_field_errors[0],
				};
			} else throw error;
		}
	}

	async logout(): Promise<boolean> {
		try {
			await axios.get(this.logoutUrl);
			return true;
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				return false;
			} else throw error;
		}
	}

	async getQr(code: string): Promise<QrAPIResponse> {
		const qrRequest = trackPromise<AxiosResponse>(
			axios.get(`${this.apiV1Url}/qr/${code}/`)
		);

		try {
			const { data } = await qrRequest;

			return { ok: true, qr: data };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}

	async getQrItems(qrCode: string): Promise<UserItemsAPIResponse> {
		const userItemsRequest = trackPromise<AxiosResponse>(
			axios.get(`${this.apiV1Url}/items/${qrCode}/`)
		);

		try {
			const { data } = await userItemsRequest;

			return { ok: true, items: data };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}

	async getItemCategories(): Promise<CategoriesAPIResponse> {
		const allItemCategoriesRequest = axios.get(
			`${this.apiV1Url}/items/categories/`
		);

		try {
			const { data } = await allItemCategoriesRequest;

			return { ok: true, categories: data };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail } = error.response?.data;
				return { error: detail };
			} else throw error;
		}
	}

	async postItemReport(
		itemReportObject: ItemReportInputs
	): Promise<BasicAPIResponse> {
		const itemReportRequest = trackPromise<AxiosResponse>(
			axios.post(`${this.apiV1Url}/items/report/`, {
				report: {
					...itemReportObject,
					item: itemReportObject.item?.value,
				},
			})
		);

		try {
			const { data } = await itemReportRequest;

			return { ok: data.ok };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}
}

const Api = new LFApi();

export default Api;
