import { useState } from "react";
import { useLocation } from "react-router-dom";

import { Link } from "../Common";
import { Brand } from "../Icons";

import { Api } from "../../services";

function Verify() {
	const location = useLocation();

	const [error, setError] = useState<{ msg: string; generic?: boolean }>({
		msg: "",
	});

	const handleResendClick = async () => {
		setError({ msg: "" });
		try {
			const response = await Api.resendVerifyEmail({
				email: location.state?.email || "",
			});

			if (response.error) setError({ msg: response.error! });
		} catch (e) {
			const error = e as Error;
			console.error(error);
			setError({ msg: error.message, generic: true });
		}
	};

	return (
		<div
			className="
        px-6
        flex
        py-12
        flex-col
        justify-center
        lg:px-8
      "
		>
			<div className="sm:mx-auto sm:w-full sm:max-w-sm">
				<div className="w-20 mx-auto">
					<Brand size={80} color="fill-green-600" />
				</div>
				<h2
					className="
            mt-5
            text-2xl
            font-bold
            leading-9
            text-center
            text-black
            tracking-tight
          "
				>
					Verify your email
				</h2>
			</div>

			<div className="mt-10 text-center sm:mx-auto sm:w-full sm:max-w-sm">
				<p>
					You are registered, before being able to use your account you need to
					verify your email address.
				</p>
				<p>We already sent a verification email to you.</p>
				<p className="mt-5 text-center">
					<span>No verification email?&nbsp;</span>
					<span className="inline-block">
						<Link text="Resend email." onClick={() => handleResendClick()} />
					</span>
				</p>

				<div className="mt-1 text-sm text-center font-semibold text-red-600">
					{error && (
						<>
							{error.generic && (
								<p>Something went wrong, please try again later!</p>
							)}
							<p>{error.msg}</p>
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export default Verify;
