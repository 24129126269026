function Landing() {
	return (
		<main>
			<div className="pt-20 sm:pt-32">
				<div
					className="
            flex
            flex-col
            text-gray-700
            place-items-center
            sm:flex-row
        "
				>
					<div className="px-6 flex-1 mb-2">
						<h2 className="mb-2 text-4xl font-bold text-center lg:text-6xl">
							¿Did you lose it?
						</h2>
						<h2 className="mb-2 text-2xl text-center lg:text-4xl">
							You could find it with us.
						</h2>
					</div>
					<div className="flex-1">
						<img
							alt="Found It"
							src="/images/FoundIt.jpg"
							className="w-5/6 rounded-lg shadow-xl mx-auto"
						/>
					</div>
				</div>
			</div>

			<div className="pt-20 lg:pt-40">
				<div
					className="
            flex
            flex-row
            bg-white
            text-gray-700
            place-items-center
          "
				>
					<div className="px-6 flex-1">
						<h2 className="mb-2 font-bold text-2xl text-center">
							¿Have you ever come across a lost object but struggled to reunite
							it with its owner?
						</h2>
						<h2 className="mb-2 text-2xl text-center">
							With Lost&Found that becomes a thing of the past.
						</h2>
					</div>
				</div>
			</div>

			<div className="pt-40 lg:pt-60">
				<div
					className="
            flex
            flex-row
            bg-white
            text-gray-700
            place-items-center
          "
				>
					<div className="px-6 flex-1">
						<h2 className="font-bold text-4xl text-center">
							¿How does Lost&Found work?
						</h2>
					</div>
				</div>
			</div>

			<div className="py-10">
				<div
					className="
            flex
            px-6
            flex-col
            space-y-6
            bg-white
            text-gray-700
            place-items-center
            sm:px-0
            sm:flex-row
            sm:space-y-0
          "
				>
					<div className="w-full sm:w-1/3">
						<img
							alt="QR"
							src="/images/QRScan.jpg"
							className="w-1/2 rounded-lg shadow-xl mx-auto"
						/>
					</div>
					<div className="w-full sm:w-2/3">
						<h2 className="mb-2 text-xl text-start lg:text-2xl">
							<b>First step:</b> When you find a lost object, just scan its
							unique QR code using your mobile phone.
						</h2>
					</div>
				</div>
			</div>

			<div className="pb-10">
				<div
					className="
            flex
            px-6
            flex-col
            space-y-6
            bg-white
            text-gray-700
            place-items-center
            sm:px-0
            sm:flex-row
            sm:space-y-0
          "
				>
					<div className="w-full sm:w-1/3">
						<img
							alt="Register"
							src="/images/Register.jpeg"
							className="w-1/2 rounded-lg shadow-xl mx-auto"
						/>
					</div>
					<div className="w-full sm:w-2/3">
						<h2 className="mb-2 text-xl text-start lg:text-2xl">
							<b>Second step:</b> The QR code will lead you directly to our
							platform, where you can effortlessly report the found object by
							providing a brief description and your contact info.
						</h2>
					</div>
				</div>
			</div>

			<div className="pb-10">
				<div
					className="
            flex
            px-6
            flex-col
            space-y-6
            bg-white
            text-gray-700
            place-items-center
            sm:px-0
            sm:flex-row
            sm:space-y-0
          "
				>
					<div className="w-full sm:w-1/3">
						<img
							alt="Contact"
							src="/images/Contact.jpeg"
							className="w-3/6 rounded-lg shadow-xl mx-auto"
						/>
					</div>
					<div className="w-full sm:w-2/3">
						<h2 className="mb-2 text-xl text-start lg:text-2xl">
							<b>Third step:</b> Platform will send your message to the object's
							owner in order to contact you.
						</h2>
					</div>
				</div>
			</div>

			<div className="pb-10">
				<div
					className="
            flex
            px-6
            flex-col
            space-y-6
            bg-white
            text-gray-700
            place-items-center
            sm:px-0
            sm:flex-row
            sm:space-y-0
          "
				>
					<div className="w-full sm:w-1/3">
						<img
							alt="Object Found"
							src="/images/Found.jpeg"
							className="w-1/2 rounded-lg shadow-xl mx-auto"
						/>
					</div>
					<div className="w-full sm:w-2/3">
						<h2 className="mb-2 text-xl text-start lg:text-2xl">
							<b>Final step:</b> Lost object will be returned to its owner and
							Lost&Found will have done its job.
						</h2>
					</div>
				</div>
			</div>

			<div className="py-20">
				<div className="px-6 bg-white text-gray-700 place-items-center">
					<p className="mb-2 text-center sm:text-lg">
						<span>Send us an email to&nbsp;</span>
						<a
							href="mailto:info@lostandfoundme.com?subject=I want more info about Lost&Found!&body=I'd like to know about you ¿Could you send me more info, please?"
							className="underline font-bold inline-block"
						>
							info@lostandfoundme.com
						</a>
						<span>&nbsp;to receive more information about Lost&Found.</span>
					</p>
				</div>
			</div>
		</main>
	);
}

export default Landing;
