import { ReactNode, createContext, useContext } from "react";
import { usePromiseTracker } from "react-promise-tracker";

import { CirclesWithBar } from "react-loader-spinner";

type LoadingProviderProps = {
	children: ReactNode;
};

type LoadingContextType = {
	isLoading: boolean;
};

const LoadingContext = createContext<LoadingContextType>({
	isLoading: false,
});

function LoadingProvider({ children }: LoadingProviderProps) {
	const { promiseInProgress } = usePromiseTracker();

	return (
		<LoadingContext.Provider value={{ isLoading: promiseInProgress }}>
			{children}
			{promiseInProgress && (
				<div
					className="
            flex
            fixed
            inset-0
            items-center
            bg-opacity-20
            bg-gray-900
            justify-center
          "
				>
					<CirclesWithBar
						width={100}
						height={100}
						color="#A2F34A"
						visible={promiseInProgress}
					/>
				</div>
			)}
		</LoadingContext.Provider>
	);
}

export const useLoadingContext = () => {
	const context = useContext(LoadingContext);

	return context;
};

export default LoadingProvider;
