type BrandProps = {
	size?: number;
	color?: string;
};

function Brand({ size, color }: BrandProps) {
	return (
		<svg
			fill="none"
			width={size}
			height={size}
			viewBox="0 0 800 800"
			data-testid="brand-icon"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="292.3" y="133.3" className={color} width="41" height="64.6" />
			<rect x="133.3" y="366.7" className={color} width="66.7" height="66.7" />
			<path
				className={color}
				d="M66.7,600H0v133.3C0,770.2,29.8,800,66.7,800H200v-66.7H66.7V600z"
			/>
			<path
				className={color}
				d="M66.7,66.7H200V0H66.7C29.8,0,0,29.8,0,66.7V200h66.7V66.7z"
			/>
			<polygon
				className={color}
				points="666.7,133.3 466.7,133.3 466.7,245.3 533.3,245.3 533.3,200 600,200 600,266.7 533.3,266.7 
		533.3,264.2 493.8,264.2 493.8,279.6 527.9,279.6 527.9,297.4 493.8,297.4 493.8,333.3 466.7,333.3 466.7,333.3 666.7,333.3 	"
			/>
			<polygon
				className={color}
				points="433.3,500 433.3,366.7 300,366.7 300,433.3 366.7,433.3 366.7,500 	"
			/>
			<rect x="366.7" y="200" className={color} width="66.7" height="133.3" />
			<polygon
				className={color}
				points="333.3,219.1 266.7,219.1 266.7,266.7 200,266.7 200,200 265.8,200 265.8,133.3 133.3,133.3 
		133.3,333.3 333.3,333.3 	"
			/>
			<rect x="533.3" y="600" className={color} width="66.7" height="66.7" />
			<path
				className={color}
				d="M733.3,733.3H600V800h133.3c36.8,0,66.7-29.8,66.7-66.7V600h-66.7V733.3z"
			/>
			<path
				className={color}
				d="M733.3,0H600v66.7h133.3V200H800V66.7C800,29.8,770.2,0,733.3,0z"
			/>
			<rect x="600" y="500" className={color} width="66.7" height="100" />
			<rect x="466.7" y="366.7" className={color} width="66.7" height="66.7" />
			<path
				className={color}
				d="M133.3,666.7h200v-200h-200V666.7z M200,533.3h66.7V600H200V533.3z"
			/>
			<rect x="533.3" y="433.3" className={color} width="66.7" height="66.7" />
			<polygon
				className={color}
				points="466.7,500 466.7,533.3 366.7,533.3 366.7,666.7 433.3,666.7 433.3,600 533.3,600 533.3,500 	"
			/>
			<rect x="600" y="366.7" className={color} width="66.7" height="66.7" />
		</svg>
	);
}

Brand.defaultProps = {
	size: 25,
	color: "fill-black",
};

export default Brand;
