type HamburgerMenuProps = {
	color?: string;
	width?: number;
	height?: number;
	className?: string;
};

function HamburgerMenu({
	color,
	width,
	height,
	className,
}: HamburgerMenuProps) {
	return (
		<svg
			fill="none"
			width={width}
			height={height}
			strokeWidth="1.5"
			viewBox="0 0 24 24"
			data-testid="hamburger-icon"
			className={`${className} ${color} stroke-current`}
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
			/>
		</svg>
	);
}

HamburgerMenu.defaultProps = {
	width: 25,
	height: 25,
	color: "stroke-current",
};

export default HamburgerMenu;
