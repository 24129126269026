import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Brand } from "../../Icons";
import { Button } from "../../Common";

import { Api, ImageExists } from "../../../services";
import { Qr } from "../../../services/types";

function QrCodeReader() {
	const { qrCode } = useParams();
	const navigate = useNavigate();

	const apiCall = useRef<boolean>(false);
	const [qr, setQr] = useState<Qr | null>(null);
	const [error, setError] = useState<{ msg: string; generic?: boolean }>({
		msg: "",
	});

	useEffect(() => {
		const getQr = async () => {
			setError({ msg: "" });
			if (!qrCode) return;

			try {
				apiCall.current = true;
				const { ok, qr, error } = await Api.getQr(qrCode || "");

				if (ok) {
					setQr(qr ?? null);
					setTimeout(() => {
						const path = qr?.user !== null ? "report" : "new";
						navigate(path, qr?.user ? { state: { user: qr?.user } } : {});
					}, 6000);
				} else setError({ msg: error! });
			} catch (e) {
				const error = e as Error;
				setError({ msg: error.message, generic: true });
			}
		};

		if (!apiCall.current) getQr();
	}, [navigate, qrCode]);

	const handleNavigate = () => {
		navigate("/", {});
	};

	return (
		<>
			{
				<div
					className="
            px-6
            flex
            py-12
            flex-row
            justify-center
            lg:px-8
          "
				>
					<div className="sm:mx-auto sm:w-full sm:max-w-sm">
						{!qr ? (
							<>
								<div className="w-20 mx-auto">
									<Brand size={80} color="fill-green-600" />
								</div>
								<h2
									className="
                    mt-5
                    text-2xl
                    font-bold
                    leading-9
                    text-center
                    text-black
                    tracking-tight
                  "
								>
									Loading QR Code, this could take some seconds wait please.
								</h2>
							</>
						) : (
							<div
								className="
                  flex
                  z-80
                  fixed
                  top-0
                  left-0
                  w-screen
                  h-screen
                  bg-white
                  items-center
                  justify-center
                  animate-logo-fade
                "
							>
								<img
									alt="QR Ad logo"
									src={
										ImageExists(qr?.ad_logo_path)
											? qr?.ad_logo_path
											: "/images/DefaultLogo.png"
									}
									className="
                    h-1/2
                    w-3/4
                    max-w-[300px]
                    max-h-[250px]
                    object-contain
                  "
								/>
							</div>
						)}

						{error.msg !== "" && (
							<>
								<div
									className="
                    my-1
                    text-sm
                    text-center
                    font-semibold
                    text-red-600
                  "
								>
									<>
										{error.generic && (
											<p>Something went wrong, please try again later!</p>
										)}
										<p>{error.msg}</p>
									</>
								</div>
								<Button
									onClick={handleNavigate}
									id="new-user-submit-button"
									className="
                    flex
                    mt-10
                    w-full
                    border-2
                    leading-6
                    shadow-sm
                    rounded-md
                    text-white
                    font-semibold
                    justify-center
                    bg-green-600
                    border-green-600
                    hover:bg-green-500
                  "
								>
									Go Home
								</Button>
							</>
						)}
					</div>
				</div>
			}
		</>
	);
}

export default QrCodeReader;
