import React from "react";
import { Button } from "../";

type MobileMenuProps = {
	show: boolean;
	activePath: string;
	handleActivePath: (path: string) => void;
};

export default React.forwardRef<HTMLDivElement, MobileMenuProps>(
	({ show, activePath, handleActivePath }, ref) => {
		if (!show) return null;

		const activeStyle = "text-white bg-green-900";
		const buttonClassName = "w-full rounded-md font-medium";
		const inactiveStyle = "text-sm text-green-300 hover:text-white";

		return (
			<div ref={ref} className="sm:hidden" id="mobile-menu">
				<div className="space-y-1 px-2 pb-3 pt-2">
					<Button
						onClick={() => handleActivePath("/app")}
						className={`
            ${buttonClassName}
            ${activePath === "/app" ? activeStyle : inactiveStyle}
          `}
					>
						Dashboard
					</Button>
				</div>
			</div>
		);
	}
);
