const Items = {
	AUTH_STATE: "state:auth",
	REDIRECT_STATE: "state:redirect",
};

const initializeState = (item: string) => {
	switch (item) {
		case Items.AUTH_STATE:
			return { token: "" };
		case Items.REDIRECT_STATE:
			return { next: "" };
		default:
			return {};
	}
};

const loadState = (item: string) => {
	try {
		const serializedState = localStorage.getItem(item);

		if (serializedState === null) {
			return initializeState(item);
		}

		return JSON.parse(serializedState);
	} catch (err) {
		return initializeState(item);
	}
};

const saveState = (state: { [key: string]: any }, item: string) => {
	try {
		localStorage.setItem(item, JSON.stringify(state));
	} catch (err) {
		console.error(err);
	}
};

const removeState = (item: string) => {
	try {
		localStorage.removeItem(item);
	} catch (err) {
		console.error(err);
	}
};

const clearState = () => localStorage.clear();

const StorePersist = { loadState, saveState, removeState, clearState, Items };

export default StorePersist;
