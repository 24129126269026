import { useNavigate } from "react-router-dom";

import { Button } from "../";
import { Dispatch } from "react";

type ProfileDropdownProps = {
  show: boolean;
  isAuthenticated: boolean;
  setToggleProfile: Dispatch<boolean>;
};

function ProfileDropdown({
  show,
  isAuthenticated,
  setToggleProfile,
}: ProfileDropdownProps) {
  const navigate = useNavigate();

  if (!show) return null;

  const handleOnClick = (path: string) => {
    navigate(path, {});
    setToggleProfile(false);
  };

  const buttonClassName = "text-black text-left hover:bg-gray-100";

  return (
    <div
      role="menu"
      tabIndex={-1}
      className="
        mt-2
        w-48
        py-1
        z-10
        flex
        border-1
        right-0
        flex-col
        absolute
        shadow-lg
        rounded-md
        bg-white
        border-black
        border-opacity-5
        origin-top-right
        focus:outline-none
      "
    >
      {isAuthenticated ? (
        <>
          <Button
            tabIndex={-1}
            id="profile-button"
            className={buttonClassName}
            onClick={() => handleOnClick("/app/profile")}
          >
            Your Profile
          </Button>
          <Button
            tabIndex={-1}
            id="logout-button"
            className={buttonClassName}
            onClick={() => handleOnClick("/auth/logout")}
          >
            Log out
          </Button>
        </>
      ) : (
        <>
          <Button
            tabIndex={-1}
            id="login-button"
            className={buttonClassName}
            onClick={() => handleOnClick("/auth/login")}
          >
            Log in
          </Button>
          <Button
            tabIndex={-1}
            id="register-button"
            className={buttonClassName}
            onClick={() => handleOnClick("/auth/register")}
          >
            Register
          </Button>
        </>
      )}
    </div>
  );
}

export default ProfileDropdown;
