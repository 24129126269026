
function TermsConditions() {
  return (
    <section>
      <div className="container mx-auto pt-10 px-6 ">
        <h2 className="text-4xl font-bold mb-2 text-center">
          Terms and conditions
        </h2>
        <div className="w-full mb-8">
          <p className="text-base mt-2 text-start">
            <b>1. Introduction </b>
          </p>
          <p className="text-base mt-2 text-start">

            Welcome to Lost&Found, a platform dedicated
            to helping users recover lost objects. These Terms and Conditions and Data
            Processing Agreement ("Agreement") govern your use of the Lost&Found platform ("Platform").
            By accessing or using the Platform, you agree to be bound by this Agreement.

          </p>
        </div>
        <div className="w-full mb-8">
          <p className="text-base mt-2 text-start">
            <b>2. User Account and Registration </b>
          </p>
          <div>
            <p className="text-base mt-2 text-start">
              <b>2.1.</b> To utilize certain features of the Platform,
              users are required to create an account. Users agree to
              provide accurate and complete information during the registration process.
            </p>
            <p className="text-base mt-2 text-start">
              <b>2.2.</b> Users are responsible for maintaining the confidentiality
              of their accounts and passwords. Users are solely responsible
              for any activity that occurs under their accounts.
            </p>
          </div>
        </div>
        <div className="w-full mb-8">
          <p className="text-base mt-2 text-start">
            <b>3. Data Collection and Usage </b>
          </p>
          <div>
            <p className="text-base mt-2 text-start">
              <b>3.1.</b> Lost&Found collects personal data provided by users during the registration
              process and while using the Platform. This data may include,
              but is not limited to, names, contact information (email, phone numbers), object descriptions,
              and other data necessary to provide the Platform's services.
            </p>
            <div>
              <p className="text-base mt-2 text-start">
                <b>3.2.</b> Lost&Found uses personal data for the following purposes:
              </p>
              <ol>
                <li><b>a.</b> To facilitate lost object recovery.</li>
                <li><b>b.</b> To communicate with users regarding lost and found updates and Platform features by making use email and Whatsapp messages.</li>
                <li><b>c.</b> To improve the Platform's functionality and user experience.</li>
                <li><b>d.</b> To comply with legal obligations; an.</li>
                <li><b>e.</b> For other purposes with user consent.</li>
              </ol>
            </div>
          </div>
        </div>
        <div className="w-full mb-8">
          <p className="text-base mt-2 text-start">
            <b>4. Data Sharing and Third-Party Service Providers </b>
          </p>
          <div>
            <p className="text-base mt-2 text-start">
              <b>4.1.</b> Lost&Found may share users' personal data with third-party service
              providers who assist in providing Platform services, such as data storage,
              customer support, and analytics. These third-party providers have access to
              data solely for specific tasks and are contractually bound to safeguard it.
            </p>
            <p className="text-base mt-2 text-start">
              <b>4.2.</b> Lost&Found may disclose personal data if required
              by law or if such disclosure is necessary to protect its rights,
              comply with legal processes, or respond to lawful requests.
            </p>
          </div>
        </div>
        <div className="w-full mb-8">
          <p className="text-base mt-2 text-start">
            <b>5. Termination </b>
          </p>
          <div>
            <p className="text-base mt-2 text-start">
              <b>5.1.</b> Lost&Found reserves the right to suspend or terminate user accounts
              and Platform access at any time without notice or liability.
            </p>
          </div>
        </div>
        <div className="w-full mb-8">
          <p className="text-base mt-2 text-start">
            <b>6. Disclaimer of Warranties </b>
          </p>
          <div>
            <p className="text-base mt-2 text-start">
              <b>6.1.</b> The Platform is provided "as is" and "as available." L
              ost&Found does not warrant that the Platform will be error-free, secure, or uninterrupted.
            </p>
          </div>
        </div>
        <div className="w-full mb-8">
          <p className="text-base mt-2 text-start">
            <b>7. Limitation of Liability </b>
          </p>
          <div>
            <p className="text-base mt-2 text-start">
              <b>7.1.</b> Lost&Found shall not be liable for any direct,
              indirect, incidental, special, consequential, or exemplary
              damages arising from Platform use or third-party content.
            </p>
          </div>
        </div>
        <div className="w-full mb-8">
          <p className="text-base mt-2 text-start">
            <b>8. Governing Law and Jurisdiction </b>
          </p>
          <div>
            <p className="text-base mt-2 text-start">
              <b>8.1.</b> This Agreement is governed by and construed in
              accordance with the laws of the State of Florida, United States.
            </p>
          </div>
        </div>
        <div className="w-full mb-8">
          <p className="text-base mt-2 text-start">
            <b>9. Changes to this Agreement </b>
          </p>
          <div>
            <p className="text-base mt-2 text-start">
              <b>9.1.</b> Lost&Found may modify this Agreement at any time.
              Changes become effective upon posting the revised Agreement
              on the Platform. Continued use after changes implies user consent.
            </p>
          </div>
        </div>
        <div className="w-full mb-8">
          <p className="text-base mt-2 text-start">
            <b>10. Data Protection </b>
          </p>
          <div>
            <p className="text-base mt-2 text-start">
              <b>10.1.</b> For information on how Lost&Found processes and protects personal
              data, please refer to our Privacy Policy, an integral part of this Agreement.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TermsConditions;
