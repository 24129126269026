/** @type {import('tailwindcss').Config} */
module.exports = {
	content: ["./src/**/*.{js,jsx,ts,tsx}"],
	theme: {
		extend: {
			maxHeight: {
				"modal-container": "calc(100vh - 15rem)",
			},
			minHeight: {
				"screen-container": "calc(100vh - 3rem)",
			},
			keyframes: {
				pulse: {
					"0%": { opacity: "0" },
					"50%": { opacity: "0.8" },
					"100%": { opacity: "0" },
				},
			},
			animation: {
				"logo-fade": "pulse 6s cubic-bezier(0.4, 0, 0.6, 1) infinite",
			},
		},
	},
	plugins: [],
};
