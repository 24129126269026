type CloseProps = {
	color?: string;
	width?: number;
	height?: number;
	className?: string;
};

function CloseMenu({ color, width, height, className }: CloseProps) {
	return (
		<svg
			fill="none"
			width={width}
			height={height}
			strokeWidth="1.5"
			viewBox="0 0 24 24"
			data-testid="close-icon"
			className={`${className} ${color} stroke-current`}
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M6 18L18 6M6 6l12 12"
			/>
		</svg>
	);
}

CloseMenu.defaultProps = {
	width: 25,
	height: 25,
	color: "stroke-current",
	className: "",
};

export default CloseMenu;
