import { Outlet } from "react-router-dom";
import { Navbar, Footer } from "./";

const Container = () => {
  return (
    <>
      <Navbar />
      <div
        className="
          pt-16
          mx-auto
          container
          max-w-7xl
          min-h-screen-container
          sm:px-6
          lg:px-8
        "
      >
        <Outlet />
      </div>
      <Footer />
    </>
  );
};
export default Container;
