function Footer() {
  return (
    <footer className="p-3 border-top text-white bg-green-600 fixed-bottom">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <span>&copy; Lost&Found - 2023</span>
      </div>
    </footer>
  );
}
export default Footer;
