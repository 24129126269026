import {
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useReducer,
} from "react";
import { Modal, ModalProps } from "../components/Common";

const INITIAL_DATA = {
	modalData: { type: "", data: null } as ModalProps["modalData"],
	modalResponse: "" as ModalProps["modalData"]["type"],
	modalResponseData: null as ModalProps["modalData"]["data"],
};

type ModalProviderProps = {
	children: ReactNode;
};

enum ModalActionTypes {
	SET_OPEN_MODAL = "SET_OPEN_MODAL",
	SET_CLOSE_MODAL = "SET_CLOSE_MODAL",
	SET_CLEAR_MODAL = "SET_CLEAR_MODAL",
}

type ModalStateType = {
	modalData: ModalProps["modalData"];
	modalResponse: ModalProps["modalData"]["type"];
	modalResponseData: ModalProps["modalData"]["data"];
	show: boolean;
};

type ModalReducerActionType = {
	modalData: ModalStateType["modalData"];
	modalResponse: ModalStateType["modalResponse"];
	modalResponseData: ModalStateType["modalResponseData"];
	show: ModalStateType["show"];
	type: keyof typeof ModalActionTypes;
};

type ModalContextType = {
	clearModalHandler: () => void;
	closeModalHandler: ({ type, data }: ModalStateType["modalData"]) => void;
	modalData: ModalStateType["modalData"];
	modalResponse: ModalStateType["modalResponse"];
	modalResponseData: ModalStateType["modalResponseData"];
	openModalHandler: ({ type, data }: ModalStateType["modalData"]) => void;
	show: ModalStateType["show"];
};

const ModalContext = createContext<ModalContextType>({
	...INITIAL_DATA,
	clearModalHandler: () => {},
	closeModalHandler: ({ type, data }: ModalStateType["modalData"]) => {},
	openModalHandler: ({ type, data }: ModalStateType["modalData"]) => {},
	show: false,
});

function modalReducer(state: ModalStateType, action: ModalReducerActionType) {
	switch (action.type) {
		case ModalActionTypes.SET_OPEN_MODAL:
			return { ...state, show: true, modalData: action.modalData };
		case ModalActionTypes.SET_CLOSE_MODAL:
			return {
				...state,
				show: false,
				modalResponse: action.modalResponse,
				modalResponseData: action.modalResponseData,
				modalData: INITIAL_DATA.modalData,
			};
		case ModalActionTypes.SET_CLEAR_MODAL:
			return {
				...state,
				modalResponse: INITIAL_DATA.modalResponse,
				modalResponseData: INITIAL_DATA.modalResponseData,
			};
		default:
			return state;
	}
}

function ModalProvider({ children }: ModalProviderProps) {
	const [modalState, dispatchModalState] = useReducer(modalReducer, {
		...INITIAL_DATA,
		show: false,
	});

	const clearModalHandler = () =>
		dispatchModalState({
			type: ModalActionTypes.SET_CLEAR_MODAL,
		} as ModalReducerActionType);

	const openModalHandler = ({ type, data }: ModalProps["modalData"]) => {
		dispatchModalState({
			modalData: { type, data },
			type: ModalActionTypes.SET_OPEN_MODAL,
		} as ModalReducerActionType);
	};

	const closeModalHandler = ({ type, data }: ModalProps["modalData"]) => {
		dispatchModalState({
			modalResponse: type,
			modalResponseData: data,
			type: ModalActionTypes.SET_CLOSE_MODAL,
		} as ModalReducerActionType);
	};

	useEffect(() => {
		if (modalState.show) document.body.style.overflow = "hidden";
		else document.body.style.overflow = "unset";
	}, [modalState.show]);

	return (
		<ModalContext.Provider
			value={{
				...modalState,
				clearModalHandler,
				closeModalHandler,
				openModalHandler,
			}}
		>
			{children}
			<Modal
				show={modalState.show}
				modalData={modalState.modalData}
				onCloseModal={closeModalHandler}
			/>
		</ModalContext.Provider>
	);
}

export const useModalContext = () => {
	const context = useContext(ModalContext);

	return context;
};

export default ModalProvider;
