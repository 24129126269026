type ArrowProps = {
	size?: number;
	color?: string;
};

function Arrow({ size, color }: ArrowProps) {
	return (
		<svg
			fill="none"
			width={size}
			height={size}
			viewBox="0 0 52 52"
			data-testid="arrow-icon"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				className={color}
				data-testid="arrow-icon-path"
				d="M38,52a2,2,0,0,1-1.41-.59l-24-24a2,2,0,0,1,0-2.82l24-24a2,2,0,0,1,2.82,0,2,2,0,0,1,0,2.82L16.83,26,39.41,48.59A2,2,0,0,1,38,52Z"
			/>
		</svg>
	);
}

Arrow.defaultProps = {
	size: 25,
	color: "fill-black",
};

export default Arrow;
