import { ChangeEvent, useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

import { Checkbox } from "../Common/Inputs";
import { Form, Link, Button } from "../Common";

import { Brand } from "../Icons";

import { Api } from "../../services";

export type RegisterInputs = {
	email: string;
	password1: string;
	password2: string;
};

function Register() {
	const formId = "register-form";

	const navigate = useNavigate();
	const { state } = useLocation();

	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [error, setError] = useState<{ msg: string; generic?: boolean }>({
		msg: "",
	});

	const { watch, handleSubmit, control, formState } = useForm<RegisterInputs>({
		mode: "onChange",
		defaultValues: {
			email: state?.email || "",
			password1: "",
			password2: "",
		},
	});

	const watchForm = watch(["email", "password1"]);

	const onSubmit: SubmitHandler<RegisterInputs> = async (form) => {
		setError({ msg: "" });
		try {
			const response = await Api.register(form);
			if (response.ok)
				navigate("/auth/verify", {
					state: { ...(state || {}), email: form.email },
				});
			else setError({ msg: response.error! });
		} catch (e) {
			const error = e as Error;
			console.error(error);
			setError({ msg: error.message, generic: true });
		}
	};

	const handleShowPassword = useCallback(
		(event: ChangeEvent<HTMLInputElement>) =>
			setShowPassword(!event.target.defaultChecked),
		[]
	);

	const disabledSubmitButton =
		!formState.isValid && "opacity-25 cursor-not-allowed pointer-events-none";

	return (
		<div
			className="
        px-6
        flex
        py-12
        flex-col
        justify-center
        lg:px-8
      "
		>
			<div className="sm:mx-auto sm:w-full sm:max-w-sm">
				<div className="w-20 mx-auto">
					<Brand size={80} color="fill-green-600" />
				</div>
				<h2
					className="
            mt-5
            text-2xl
            font-bold
            leading-9
            text-center
            text-black
            tracking-tight
          "
				>
					Register your account
				</h2>
			</div>

			<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
				<Form id={formId} onSubmit={handleSubmit(onSubmit)}>
					<Form.Text
						type="email"
						name="email"
						label="email"
						id="email-input"
						required={true}
						control={control}
						errors={formState.errors}
						rules={{
							pattern: {
								value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
								message: "Enter a valid email.",
							},
							required: { value: true, message: "Email is required." },
						}}
					/>
					<Form.ErrorMessage errors={formState.errors} name="email" />

					<Form.Text
						required={true}
						name="password1"
						label="password"
						control={control}
						id="password1-input"
						errors={formState.errors}
						type={showPassword ? "text" : "password"}
						rules={{
							required: { value: true, message: "Password is required." },
							validate: {
								lenght: (v: string) =>
									v.length >= 8 || "Password should be at least 8 characters",
								number: (v: string) =>
									/\d/gm.test(v) || "Password should have at least 1 number.",
								letter: (v: string) =>
									/\D/gm.test(v) || "Password should have at least 1 letter.",
							},
						}}
					/>
					<Form.ErrorMessage errors={formState.errors} name="password1" />

					<Form.Text
						required={true}
						name="password2"
						control={control}
						id="password2-input"
						label="verifiy password"
						errors={formState.errors}
						type={showPassword ? "text" : "password"}
						rules={{
							required: { value: true, message: "Password is required." },
							validate: {
								lenght: (v: string) =>
									v.length >= 8 || "Password should be at least 8 characters",
								number: (v: string) =>
									/\d/gm.test(v) || "Password should have at least 1 number.",
								letter: (v: string) =>
									/\D/gm.test(v) || "Password should have at least 1 letter.",
								verify: (v: string) =>
									v === watchForm[1] || "Password should be the same.",
							},
						}}
					/>
					<Form.ErrorMessage errors={formState.errors} name="password2" />

					<div>
						<div className="flex flex-row items-center">
							<Checkbox
								label="Show password"
								checked={showPassword}
								id="show-password-checkbox"
								onChange={handleShowPassword}
							/>
						</div>
					</div>

					<div className="mt-1 text-sm text-center font-semibold text-red-600">
						{error && (
							<>
								{error.generic && (
									<p>Something went wrong, please try again later!</p>
								)}
								<p>{error.msg}</p>
							</>
						)}
					</div>

					<div className="mt-6">
						<p className="text-sm text-center text-gray-500">
							<span>By registering, you agree to the&nbsp;</span>
							<span className="inline-block">
								<Link
									type="button"
									target="_blank"
									text="terms and conditions"
									href="/auth/terms-conditions"
								/>
							</span>
							<span>&nbsp;of the platform.</span>
						</p>
					</div>
					<Button
						form={formId}
						type="submit"
						value="submit"
						id="login-submit-button"
						disabled={!formState.isValid}
						className={`
              flex
              mt-4
              w-full
              border-2
              leading-6
              shadow-sm
              rounded-md
              text-white
              font-semibold
              justify-center
              bg-green-600
              border-green-600
              hover:bg-green-500
              ${disabledSubmitButton}
            `}
					>
						Register
					</Button>
				</Form>

				<div className="mt-10">
					<p className="text-sm text-center text-gray-500">
						<span>Already a member?&nbsp;</span>
						<span className="inline-block">
							<Link
								type="button"
								text="Log in"
								onClick={() =>
									navigate("/auth/login", {
										state: { ...(state || {}), email: watchForm[0] },
									})
								}
							/>
						</span>
					</p>
				</div>
			</div>
		</div>
	);
}

export default Register;
