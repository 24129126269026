import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

import UserProvider from "./providers/UserProvider";
import ModalProvider from "./providers/ModalProvider";
import LoadingProvider from "./providers/LoadingProvider";

import App from "./components/App";

import "./styles/index.css";
import "./styles/tailwind.output.css";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<GoogleOAuthProvider
				clientId={(window as any).SERVER_DATA.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
			>
				<UserProvider>
					<ModalProvider>
						<LoadingProvider>
							<App />
						</LoadingProvider>
					</ModalProvider>
				</UserProvider>
			</GoogleOAuthProvider>
		</BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
