import React, { ChangeEvent, ReactElement } from "react";

import { Controller, FieldErrors } from "react-hook-form";
import { ErrorMessage, Props } from "@hookform/error-message";

import { Text, Select, Toggle, Textarea, Checkbox } from "../Inputs";
import { FormInputProps, FormProps, SelectProps, TextAreaPropd } from "./types";

function Form({ id, children, onSubmit }: FormProps) {
  return (
    <form id={id} onSubmit={onSubmit} className="flex flex-1 flex-col">
      {children}
    </form>
  );
}

Form.Text = ({
  id,
  type,
  label,
  errors,
  subLabel,
  transform,
  textUpper,
  placeholder,
  textCapitalize,
  ...restProps
}: FormInputProps) => (
  <Controller
    {...restProps}
    render={({ field }) => (
      <Text
        id={id}
        type={type}
        label={label}
        errors={errors}
        subLabel={subLabel}
        name={restProps.name}
        textUpper={textUpper}
        placeholder={placeholder}
        required={restProps.required}
        disabled={restProps.disabled}
        textCapitalize={textCapitalize}
        value={transform ? transform.input(field.value) : field.value}
        onChange={
          transform
            ? (e: ChangeEvent<HTMLInputElement>) =>
                field.onChange(transform.output(e))
            : field.onChange
        }
      />
    )}
  />
);

Form.Textarea = ({
  id,
  label,
  errors,
  subLabel,
  textUpper,
  placeholder,
  textCapitalize,
  ...restProps
}: TextAreaPropd) => (
  <Controller
    {...restProps}
    render={({ field }) => (
      <Textarea
        id={id}
        label={label}
        errors={errors}
        subLabel={subLabel}
        value={field.value}
        name={restProps.name}
        rows={restProps.rows}
        textUpper={textUpper}
        placeholder={placeholder}
        onChange={field.onChange}
        required={restProps.required}
        disabled={restProps.disabled}
        textCapitalize={textCapitalize}
      />
    )}
  />
);

Form.Checkbox = ({
  id,
  label,
  textUpper,
  textCapitalize,
  ...restProps
}: FormInputProps) => (
  <Controller
    {...restProps}
    render={({ field }) => (
      <Checkbox
        id={id}
        label={label}
        value={field.value}
        checked={field.value}
        name={restProps.name}
        textUpper={textUpper}
        required={restProps.required}
        disabled={restProps.disabled}
        textCapitalize={textCapitalize}
        onChange={(e: ChangeEvent<{ checked: boolean }>) =>
          field.onChange(e.target.checked)
        }
      />
    )}
  />
);

Form.Toggle = ({
  id,
  label,
  textUpper,
  textCapitalize,
  ...restProps
}: FormInputProps) => (
  <Controller
    {...restProps}
    render={({ field }) => (
      <Toggle
        id={id}
        label={label}
        value={field.value}
        checked={field.value}
        name={restProps.name}
        textUpper={textUpper}
        required={restProps.required}
        disabled={restProps.disabled}
        textCapitalize={textCapitalize}
        onChange={(e: ChangeEvent<{ checked: boolean }>) =>
          field.onChange(e.target.checked)
        }
      />
    )}
  />
);

Form.Select = <T extends { value: string | number; label: string }>({
  id,
  multi,
  label,
  subLabel,
  checkbox,
  textUpper,
  loadOptions,
  placeholder,
  closeOnSelect,
  textCapitalize,
  ...restProps
}: SelectProps<T>) => (
  <Controller
    {...restProps}
    render={({ field }) => (
      <Select
        id={id}
        multi={multi}
        label={label}
        value={field.value}
        subLabel={subLabel}
        checkbox={checkbox}
        textUpper={textUpper}
        loadOptions={loadOptions}
        placeholder={placeholder}
        onChange={field.onChange}
        closeOnSelect={closeOnSelect}
        required={restProps.required}
        disabled={restProps.disabled}
        textCapitalize={textCapitalize}
        defaultValue={restProps.defaultValue}
      />
    )}
  />
);

Form.ErrorMessage = (props: Props<FieldErrors, ReactElement>) => (
  <ErrorMessage
    {...props}
    render={({ message }) => (
      <p className="text-sm font-semibold text-red-600">{message}</p>
    )}
  />
);

export default Form;
