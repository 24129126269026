function ImageExists(imageUrl: string) {
	if (imageUrl === "") return false;

	var http = new XMLHttpRequest();
	http.open("GET", imageUrl, false);
	http.send();

	return http.status !== 404;
}

export { ImageExists };
