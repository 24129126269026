import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Brand } from "../../Icons";
import { Button, ModalType } from "../../Common";

import { useAuthentication } from "../../../services";
import { useUserContext } from "../../../providers/UserProvider";
import { useModalContext } from "../../../providers/ModalProvider";

function NewUser() {
	useAuthentication();
	const navigate = useNavigate();
	const { qrCode } = useParams();

	const modalContext = useModalContext();
	const { user, isAuthenticated } = useUserContext();

	useEffect(() => {
		if (
			isAuthenticated &&
			!modalContext.show &&
			(!user.phoneNumber || !user.phoneNumberAlt)
		)
			modalContext.openModalHandler({
				type: ModalType.UPDATE_PROFILE,
				data: { title: "Update Profile" },
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, modalContext.show]);

	useEffect(() => {
		if (modalContext.modalResponse === ModalType.ADD_QR) {
			navigate(`/app/${qrCode}`);
		}

		modalContext.clearModalHandler();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modalContext.modalResponse]);

	const handleNavigate = (add?: boolean) => {
		if (add)
			modalContext.openModalHandler({
				type: ModalType.ADD_QR,
				data: { title: "Add QR Code", code: qrCode },
			});
		else navigate("/", {});
	};

	return (
		<>
			{
				<div
					className="
            px-6
            flex
            py-12
            flex-row
            justify-center
            lg:px-8
          "
				>
					<div className="sm:mx-auto sm:w-full sm:max-w-sm">
						<div className="w-20 mx-auto">
							<Brand size={80} color="fill-green-600" />
						</div>
						<h2
							className="
                mt-5
                text-2xl
                font-bold
                leading-9
                text-center
                text-black
                tracking-tight
              "
						>
							New QR Code ¿Do you want to add it to your account?
						</h2>

						<Button
							onClick={() => handleNavigate(true)}
							id="add-qr-code-button"
							className="
                flex
                mt-10
                w-full
                border-2
                leading-6
                shadow-sm
                rounded-md
                text-white
                font-semibold
                justify-center
                bg-green-600
                border-green-600
                hover:bg-green-500
              "
						>
							Add QR Code
						</Button>
						<Button
							id="cancel-new-qr-code-button"
							onClick={() => handleNavigate()}
							className="
                flex
                mt-5
                w-full
                border-2
                leading-6
                shadow-sm
                rounded-md
                bg-white
                font-semibold
                justify-center
                text-green-600
                border-green-600
                hover:bg-green-100
              "
						>
							Cancel
						</Button>
					</div>
				</div>
			}
		</>
	);
}

export default NewUser;
