import {
	components,
	ActionMeta,
	OptionProps,
	OnChangeValue,
} from "react-select";
import AsyncSelect from "react-select/async";

import { InputProps } from "./types";

type SelectProps<T> = Omit<InputProps, "value"> & {
	value: T;
	defaultValue: T;
	multi?: boolean;
	checkbox?: boolean;
	closeOnSelect?: boolean;
	loadOptions: (inputValue: string, callback: (options: T[]) => void) => void;
	onChange: (
		newValue: OnChangeValue<T, boolean>,
		actionMeta: ActionMeta<T>
	) => void;
};

function Select<T>({
	multi,
	label,
	value,
	required,
	disabled,
	onChange,
	subLabel,
	checkbox,
	textUpper,
	loadOptions,
	placeholder,
	defaultValue,
	closeOnSelect,
	textCapitalize,
}: SelectProps<T>) {
	const Option = (props: OptionProps<T>) => {
		return (
			<components.Option {...props}>
				<div className="flex flex-1 flex-row space-x-2">
					<input
						type="checkbox"
						onChange={() => null}
						checked={props.isSelected}
					/>
					<label>{props.label}</label>
				</div>
			</components.Option>
		);
	};

	return (
		<div className={`py-2 ${disabled ? "cursor-not-allowed" : ""}`}>
			<div className={`mb-2 ${disabled && "opacity-40"}`}>
				{label && (
					<span
						className={`
              flex
              flex-row
              text-left
              font-semibold
              text-green-600
              ${textCapitalize && "capitalize"}
              ${textUpper ? "uppercase" : "normal-case"}
            `}
					>
						{label}
						{required && <div className="px-1 font-bold">*</div>}
					</span>
				)}
				{subLabel && <span className="font-medium text-black">{subLabel}</span>}
			</div>
			<AsyncSelect
				cacheOptions
				value={value}
				defaultOptions
				isMulti={multi}
				onChange={onChange}
				isDisabled={disabled}
				placeholder={placeholder}
				loadOptions={loadOptions}
				defaultValue={defaultValue}
				menuShouldScrollIntoView={true}
				components={checkbox ? { Option } : undefined}
				hideSelectedOptions={checkbox ? false : undefined}
				closeMenuOnSelect={checkbox ? false : closeOnSelect}
				styles={{
					control: (base, state) => ({
						...base,
						fontSize: "16px",
						padding: "3px 2px",
						borderRadius: "6px",
						cursor: state.isDisabled ? "not-allowed" : "default",
						boxShadow: state.isFocused ? "0 1px 4px 0 #000000" : "none",
						border: state.isDisabled
							? "2px solid rgba(22, 163, 74, .5)"
							: "2px solid rgba(22, 163, 74, 1)",
						"&:hover": { border: "2px solid rgba(22, 163, 74, 1)" },
					}),
					valueContainer: (base) => ({
						...base,
						maxHeight: 90,
						fontWeight: 500,
						overflow: "auto",
						textAlign: "left",
					}),
					dropdownIndicator: (base, state) => ({
						...base,
						color: "#000000",
						cursor: "pointer",
						opacity: state.isDisabled ? 0.5 : 1,
						"&:hover": { color: "#2F855A" },
					}),
					clearIndicator: (base) => ({
						...base,
						color: "#000000",
						cursor: "pointer",
						"&:hover": { color: "#2F855A" },
					}),
					indicatorSeparator: (base, state) => ({
						...base,
						border: "1px solid rgba(22, 163, 74, 1)",
						opacity: state.isDisabled ? 0.5 : 1,
					}),
					menu: (base) => ({
						...base,
						borderTop: 0,
						marginTop: 0,
						maxHeight: 200,
						padding: "0 5px",
						fontSize: "16px",
						borderRadius: "6px",
						minHeight: "fit-content",
						boxShadow: "0 1px 4px 0 #000000",
						borderLeft: "2px solid rgba(22, 163, 74, 1)",
						borderRight: "2px solid rgba(22, 163, 74, 1)",
						borderBottom: "2px solid rgba(22, 163, 74, 1)",
					}),
					menuList: (base) => ({
						...base,
						maxHeight: 200,
						textAlign: "left",
					}),
					option: (base, state) => ({
						...base,
						color: state.isSelected ? "#FFFFFF" : "#000000",
						backgroundColor: state.isSelected
							? "#2F855A"
							: state.isFocused
							? "#81c784"
							: "transparent",
						"&:hover": {
							color: "#000000",
							backgroundColor: "#81c784",
						},
						"&:active": {
							color: "#000000",
							backgroundColor: "#81c784",
						},
					}),
					multiValue: (base) => ({
						...base,
						fontWeight: 500,
						borderRadius: "6px",
						backgroundColor: "#81c784",
					}),
					multiValueRemove: (base) => ({
						...base,
						color: "#000000",
						"&:hover": { color: "#000000", backgroundColor: "transparent" },
					}),
				}}
			/>
		</div>
	);
}

Select.defaultProps = { textCapitalize: true };

export default Select;
