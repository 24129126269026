import { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { Button, Form, ModalProps, ModalType } from "../../../Common";

import { Api } from "../../../../services";
import { Category, Item } from "../../../../services/types";

type AddItemModalProps = {
  onCloseModal: ModalProps["onCloseModal"];
};

type ItemOption = {
  value: number;
  label: string;
};

export type CreateItemsInputs = {
  item: string;
  category: ItemOption | null;
};

function AddItemModal({ onCloseModal }: AddItemModalProps) {
  const formId = "add-qr-items-form";

  const apiCall = useRef<boolean>(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [error, setError] = useState<{ msg: string; generic?: boolean }>({
    msg: "",
  });

  useEffect(() => {
    const getCategories = async () => {
      try {
        apiCall.current = true;
        const { ok, error, categories } = await Api.getItemCategories();
        if (ok && categories) {
          setCategories(categories);
        } else setError({ msg: error! });
      } catch (e) {
        const error = e as Error;
        setError({ msg: error.message, generic: true });
      }
    };

    if (!apiCall.current) getCategories();
  }, []);

  const { handleSubmit, control, formState } = useForm<CreateItemsInputs>({
    mode: "onChange",
    defaultValues: {
      item: "",
      category: null,
    },
  });

  const onSubmit: SubmitHandler<CreateItemsInputs> = async (form) => {
    const newCategory: Item = {
      name: form.item,
      category: categories.find(
        (category) => category.id === form.category?.value
      )!,
    };

    onCloseModal({ type: ModalType.ADD_ITEM, data: newCategory });
  };

  const fetchCategories = (
    search: string,
    callback: (options: ItemOption[]) => void
  ) => {
    const options: ItemOption[] = categories.map((category) => ({
      value: category.id,
      label: category.name,
    }));

    callback(
      options.filter((option) =>
        option.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
    );
  };

  const disabledSubmitButton =
    !formState.isValid && "opacity-25 cursor-not-allowed pointer-events-none";

  if (categories.length === 0) return null;
  return (
    <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <div className="h-80 max-h-modal-container overflow-scroll">
        <Form.Select
          name="category"
          required={true}
          label="Category"
          control={control}
          id="category-input"
          errors={formState.errors}
          loadOptions={fetchCategories}
          rules={{
            required: {
              value: true,
              message: "Category is required.",
            },
          }}
        />
        <Form.ErrorMessage errors={formState.errors} name="category" />
        <Form.Text
          name="item"
          id="item-input"
          required={true}
          label="Item Name"
          control={control}
          errors={formState.errors}
          rules={{
            required: {
              value: true,
              message: "Item Name is required.",
            },
          }}
        />
        <Form.ErrorMessage errors={formState.errors} name="item" />
        <div className="mt-1 text-sm text-center font-semibold text-red-600">
          {error.msg !== "" && (
            <>
              {error.generic && (
                <p>Something went wrong, please try again later!</p>
              )}
              <p>{error.msg}</p>
            </>
          )}
        </div>
      </div>

      <div className="mt-5 sm:flex sm:flex-row-reverse">
        <Button
          form={formId}
          type="submit"
          value="submit"
          id="create-item-submit-button"
          disabled={!formState.isValid}
          className={`
            flex
            mt-5
            w-full
            border-2
            leading-6
            shadow-sm
            rounded-md
            text-white
            font-semibold
            justify-center
            bg-green-600
            border-green-600
            hover:bg-green-500
            ${disabledSubmitButton}
          `}
        >
          Add Item
        </Button>
      </div>
    </Form>
  );
}

export default AddItemModal;
