import { useEffect, useState } from "react";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../tailwind.config";

const { theme } = resolveConfig(tailwindConfig);

type Screens = {
  sm: string;
  md: string;
  lg: string;
  xl: string;
  "2xl": string;
};
type BreakpointKey = "sm" | "md" | "lg" | "xl" | "2xl";

const breakpoints = theme?.screens! as Screens;

function useBreakpoint<T extends BreakpointKey>(breakpointKey: T) {
  type Key = `is${Capitalize<T>}`;

  const mql = window.matchMedia(`(max-width: ${breakpoints[breakpointKey]})`);
  const [state, setState] = useState<boolean>(mql.matches);

  useEffect(() => {
    function listener(this: MediaQueryList) {
      setState(this.matches);
    }

    mql.addListener(listener);
    return function clean() {
      mql.removeListener(listener);
    };
  });

  const capitalizedKey =
    breakpointKey?.[0].toUpperCase() + breakpointKey.substring(1);

  return {
    [`is${capitalizedKey}`]: state,
  } as Record<Key, boolean>;
}

export default useBreakpoint;
