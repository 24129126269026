import { useEffect, useRef, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Brand } from "../../Icons";
import { Form, Button } from "../../Common";

import { Api } from "../../../services";
import { Item } from "../../../services/types";
import { useLoadingContext } from "../../../providers/LoadingProvider";
import { formatPhoneNumber } from "../Profile";

type ItemOption = {
	value: number;
	label: string;
};

export type ItemReportInputs = {
	user: number;
	item: ItemOption | null;
	phone_number?: string;
	email?: string;
	message?: string;
};

function QrCodeReport() {
	const formId = "qr-code-report-form";

	const { qrCode } = useParams();
	const { isLoading } = useLoadingContext();

	const navigate = useNavigate();
	const { state } = useLocation();

	const apiCall = useRef<boolean>(false);
	const [items, setItems] = useState<Item[]>([]);
	const [ready, setReady] = useState<boolean>(false);
	const [reportSend, setReportSend] = useState<boolean>(false);
	const [error, setError] = useState<{ msg: string; generic?: boolean }>({
		msg: "",
	});

	const { watch, handleSubmit, control, formState } = useForm<ItemReportInputs>(
		{
			mode: "onChange",
			defaultValues: {
				item: null,
				phone_number: "",
				email: "",
				message: "",
			},
		}
	);

	const watchForm = watch(["item", "phone_number", "email", "message"]);

	useEffect(() => {
		const getQrItems = async () => {
			if (!qrCode) return;

			try {
				apiCall.current = true;
				const { ok, items, error } = await Api.getQrItems(qrCode);

				setReady(true);
				if (ok) setItems(items!);
				else setError({ msg: error! });
			} catch (e) {
				const error = e as Error;
				setError({ msg: error.message, generic: true });
			}
		};

		if (!apiCall.current) getQrItems();
	}, [qrCode]);

	const fetchItems = (
		search: string,
		callback: (options: ItemOption[]) => void
	) => {
		const options: ItemOption[] = items.map((item) => ({
			value: item.id!,
			label: item.name,
		}));

		callback(
			options.filter((option) =>
				option.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
			)
		);
	};

	const onSubmit: SubmitHandler<ItemReportInputs> = async (form) => {
		setError({ msg: "" });
		const { item, email, phone_number, message } = form;

		if (email === "" && phone_number === "" && message === "") {
			setError({
				msg: "At least one of the fields must have information to be able to contact you.",
			});
			return;
		}

		const submittedData = {
			user: Number(state.user),
			item: item,
			email,
			phone_number,
			message,
		};

		try {
			const { ok, error } = await Api.postItemReport(submittedData);

			if (ok) {
				setReportSend(true);
				setTimeout(() => navigate("/", {}), 3000);
			} else setError({ msg: error! });
		} catch (e) {
			const error = e as Error;
			setError({ msg: error.message, generic: true });
		}
	};

	const transformPhoneInput = {
		input: (value: string) => {
			if (value !== "") {
				let newValue = value;
				newValue = formatPhoneNumber(newValue)!;
				return newValue ? newValue : value;
			}

			return "";
		},
		output: (event: React.ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value.replace(/\D/g, "");

			return value;
		},
	};

	const formValid =
		(watchForm[1] !== "" || watchForm[2] !== "" || watchForm[3] !== "") &&
		formState.isValid;
	const disabledSubmitButton =
		!formValid && "opacity-25 cursor-not-allowed pointer-events-none";

	return (
		<div
			className="
        px-6
        flex
        py-12
        flex-col
        justify-center
        lg:px-8
      "
		>
			<div className="sm:mx-auto sm:w-full sm:max-w-sm">
				<div className="w-20 mx-auto">
					<Brand size={80} color="fill-green-600" />
				</div>
				<h2
					className="
            mt-5
            text-2xl
            font-bold
            leading-9
            text-center
            text-black
            tracking-tight
          "
				>
					Report Lost Item
				</h2>
			</div>

			{!isLoading && ready && (
				<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
					{items.length > 0 && !reportSend ? (
						<>
							<p className="mb-2 text-lg font-semibold">
								Please leave your contact information, so the owner of the lost
								item can get in touch with you.
							</p>
							<Form id={formId} onSubmit={handleSubmit(onSubmit)}>
								<Form.Select
									name="item"
									label="Item Found"
									id="item-input"
									required={true}
									control={control}
									loadOptions={fetchItems}
									errors={formState.errors}
									rules={{
										required: {
											value: true,
											message: "This field is required.",
										},
									}}
								/>
								<Form.ErrorMessage errors={formState.errors} name="item" />
								<Form.Text
									type="text"
									name="email"
									label="email"
									id="email-input"
									required={false}
									control={control}
									errors={formState.errors}
									rules={{
										pattern: {
											value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
											message: "Enter a valid email.",
										},
									}}
								/>
								<Form.ErrorMessage errors={formState.errors} name="email" />
								<Form.Text
									required={false}
									control={control}
									name="phone_number"
									id="phone-number-input"
									placeholder="17065554433"
									errors={formState.errors}
									transform={transformPhoneInput}
									label="Phone Number (Use country code)"
									rules={{
										maxLength: {
											value: 13,
											message: "Phone Number is too long.",
										},
										pattern: {
											value:
												/\+?\d{1,3}?\s*\(?-*\.*(\d{3})\)?\.*-*\s*(\d{3})\.*-*\s*(\d{4})$/,
											message: "Phone Number is invalid.",
										},
									}}
								/>
								<Form.ErrorMessage
									errors={formState.errors}
									name="phone_number"
								/>

								<Form.Textarea
									rows={4}
									name="message"
									label="Message"
									required={false}
									control={control}
									id="message-input"
									errors={formState.errors}
								/>

								<div className="mt-1 text-sm text-center font-semibold text-red-600">
									{error.msg !== "" && (
										<>
											{error.generic && (
												<p>Something went wrong, please try again later!</p>
											)}
											<p>{error.msg}</p>
										</>
									)}
								</div>

								<Button
									form={formId}
									type="submit"
									value="submit"
									id="found-item-submit-button"
									disabled={!formValid}
									className={`
                    flex
                    mt-10
                    w-full
                    border-2
                    leading-6
                    shadow-sm
                    rounded-md
                    text-white
                    font-semibold
                    justify-center
                    bg-green-600
                    border-green-600
                    hover:bg-green-500
                    ${disabledSubmitButton}
                  `}
								>
									Report Lost Item
								</Button>
							</Form>
						</>
					) : (
						<h2
							className="
                mt-5
                text-2xl
                font-bold
                leading-9
                text-center
                text-black
                tracking-tight
              "
						>
							{reportSend
								? "Report sent successfully."
								: "Not found associated items."}
						</h2>
					)}
				</div>
			)}
		</div>
	);
}

export default QrCodeReport;
