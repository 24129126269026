import { AxiosHeaders } from "axios";

export enum Methods {
	"GET" = "GET",
	"PUT" = "PUT",
	"POST" = "POST",
	"HEAD" = "HEAD",
	"PATCH" = "PATCH",
	"DELETE" = "DELETE",
	"OPTIONS" = "OPTIONS",
}

export type CustomHeaderProperties = AxiosHeaders & {
	Authorization: string;
};

// Input Types
export {
	type LoginInputs,
	type RegisterInputs,
	type ResetPasswordInputs,
	type ResetPasswordConfirmInputs,
} from "../components/Auth";

export { type ItemReportInputs } from "../components/Pages/QrCode";
export { type PatchUserInputs } from "../components/Pages/Profile/Modals";

export type LoginGoogleInputs = {
	code: string;
};

export type LoginFacebookInputs = {
	accessToken: string;
};

export type VerifyEmailInputs = {
	key: string;
};

export type ResendVerifyEmailInputs = {
	email: string;
};

export type PatchQrUserInputs = {
	code: string;
	user: number;
};

export type ManageQrUserItemsInputs = {
	items: Item[];
	qrCode: string;
};

// Response Types
export type BasicAPIResponse = {
	ok?: boolean;
	msg?: string;
	error?: string;
};

export type User = {
	id: number;
	email: string;
	first_name: string;
	last_name: string;
	phone_number: string;
	phone_number_alt: string;
	is_active: boolean;
};
export type UserAPIResponse = BasicAPIResponse & {
	user?: User;
};

export type VerifyAuthAPIResponse = BasicAPIResponse & {
	isAuthenticated?: boolean;
};

export type Qr = {
	id: number;
	code: string;
	user: number;
	active: boolean;
	created_date: string;
	assigned_date: string;
	ad_logo_path: string;
	items_limit: number;
};
export type QrAPIResponse = BasicAPIResponse & {
	qr?: Qr;
};

export type QrUser = Qr & {
	items: Item[];
};
export type QrUserAPIResponse = BasicAPIResponse & {
	qr?: QrUser;
};

export type QrListUserAPIResponse = BasicAPIResponse & {
	qrs?: Qr[];
};

export type Item = {
	id?: number;
	qr?: number;
	name: string;
	category: Category;
};
export type UserItemsAPIResponse = BasicAPIResponse & {
	items?: Item[];
};

export type Category = {
	id: number;
	name: string;
};
export type CategoriesAPIResponse = BasicAPIResponse & {
	categories?: Category[];
};
