import { ReactNode } from "react";
import { useAuthentication } from "../../services";

type PrivateRouteProps = {
  children: ReactNode;
};

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const { isAuthenticated } = useAuthentication();

  return <>{isAuthenticated ? children : null}</>;
};
export default PrivateRoute;
