import { useEffect } from "react";

function useOutsideAlerter(
  ref: React.RefObject<HTMLDivElement>,
  callback: (value: boolean) => void
) {
  useEffect(() => {
    function handleClickOutside({ target }: MouseEvent) {
      if (ref.current && !ref.current.contains(target as Node)) callback(true);
      else callback(false);
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}

export default useOutsideAlerter;
