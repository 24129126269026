import { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { FacebookLoginClient } from "@greatsumini/react-facebook-login";

import { Container, NotFound, PrivateRoute } from "./Common";
import {
	Login,
	Logout,
	Verify,
	Register,
	VerifyEmail,
	ResetPassword,
	ResetPasswordConfirm,
} from "./Auth";

import {
	Landing,
	QrCode,
	QrCodeNew,
	QrCodeList,
	QrCodeReader,
	QrCodeReport,
	Profile,
	TermsConditions,
} from "./Pages";

import { useVerifyAuth } from "../services";

function App() {
	useVerifyAuth();

	useEffect(() => {
		(async () => {
			await FacebookLoginClient.loadSdk("en_US");
			FacebookLoginClient.init({
				appId: (window as any).SERVER_DATA.REACT_APP_FACEBOOK_OAUTH_CLIENT_ID,
				version: "v16.0",
			});
		})();
	}, []);

	return (
		<Routes>
			<Route path="/" element={<Container />}>
				<Route path="*" element={<NotFound />} />
				<Route path="" element={<Landing />} />
				<Route path="auth">
					<Route path="" element={<Navigate to="login" />} />
					<Route path="login" element={<Login />} />
					<Route path="logout" element={<Logout />} />
					<Route path="verify" element={<Verify />} />
					<Route path="register" element={<Register />} />
					<Route path="reset-password" element={<ResetPassword />} />
					<Route path="verify-email/:key" element={<VerifyEmail />} />
					<Route path="terms-conditions" element={<TermsConditions />} />
					<Route
						path="reset-password-confirm/:id/:token"
						element={<ResetPasswordConfirm />}
					/>
				</Route>
				<Route path="app">
					<Route
						path=""
						element={
							<PrivateRoute>
								<QrCodeList />
							</PrivateRoute>
						}
					/>
					<Route
						path="profile"
						element={
							<PrivateRoute>
								<Profile />
							</PrivateRoute>
						}
					/>
					<Route
						path=":qrCode"
						element={
							<PrivateRoute>
								<QrCode />
							</PrivateRoute>
						}
					/>
				</Route>
				<Route path="qr">
					<Route path="" element={<Navigate to="/" />} />
					<Route path=":qrCode" element={<QrCodeReader />} />
					<Route path=":qrCode/new" element={<QrCodeNew />} />
					<Route path=":qrCode/report" element={<QrCodeReport />} />
				</Route>
			</Route>
		</Routes>
	);
}

export default App;
