import { useState } from "react";

import { Button, ModalProps, ModalType } from "../../../Common";

import { Api } from "../../../../services";
import { useUserContext } from "../../../../providers/UserProvider";

type AddQrModalProps = {
  code: string;
  onCloseModal: ModalProps["onCloseModal"];
};

function AddQrModal({ code, onCloseModal }: AddQrModalProps) {
  const { user } = useUserContext();

  const [error, setError] = useState<{ msg: string; generic?: boolean }>({
    msg: "",
  });

  const handleOnClickAddQrCode = async () => {
    setError({ msg: "" });
    if (!code) return;

    try {
      const { ok, error } = await Api.patchQrUser({
        code,
        user: Number(user.id),
      });

      if (ok) onCloseModal({ type: ModalType.ADD_QR, data: null });
      else setError({ msg: error! });
    } catch (e) {
      const error = e as Error;
      setError({ msg: error.message, generic: true });
    }
  };

  return (
    <div className="flex flex-1 flex-col">
      <p
        className="
          text-xl
          font-bold
          leading-9
          text-center
          text-black
          tracking-tight
        "
      >
        <span>Are you sure, you want to add QR Code: </span>
        <strong className="text-green-600">{code} </strong>
        <span>to your account?</span>
      </p>

      <div className="mt-1 text-sm text-center font-semibold text-red-600">
        {error.msg !== "" && (
          <>
            {error.generic && (
              <p>Something went wrong, please try again later!</p>
            )}
            <p>{error.msg}</p>
          </>
        )}
      </div>

      <div
        className="
          mt-5
          space-y-2
          sm:flex
          sm:space-x-2
          sm:space-y-0
          sm:space-x-reverse
          sm:flex-row-reverse
      "
      >
        <Button
          id="add-qr-modal-button"
          onClick={handleOnClickAddQrCode}
          className="
            flex
            w-full
            border-2
            leading-6
            shadow-sm
            rounded-md
            text-white
            font-semibold
            justify-center
            bg-green-600
            border-green-600
            hover:bg-green-500
          "
        >
          Add QR Code
        </Button>
        <Button
          id="cancel-qr-modal-button"
          onClick={() => onCloseModal({ type: "", data: null })}
          className="
            flex
            w-full
            border-2
            leading-6
            shadow-sm
            rounded-md
            bg-white
            font-semibold
            justify-center
            text-green-600
            border-green-600
            hover:text-white
            hover:bg-green-500
          "
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default AddQrModal;
