type CheckProps = {
	size?: number;
	color?: string;
};

function Check({ size, color }: CheckProps) {
	return (
		<svg
			fill="none"
			width={size}
			height={size}
			viewBox="0 0 20 20"
			data-testid="check-icon"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				className={color}
				data-testid="check-icon-path"
				d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
			/>
		</svg>
	);
}

Check.defaultProps = {
	size: 25,
	color: "fill-black",
};

export default Check;
