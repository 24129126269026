import { ReactNode, MouseEvent } from "react";

type ButtonProps = {
  id?: string;
  form?: string;
  value?: string;
  tabIndex?: number;
  padding?: boolean;
  disabled?: boolean;
  className?: string;
  children: ReactNode;
  type?: "submit" | "reset" | "button" | undefined;
  onClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
};

function Button({
  padding,
  children,
  disabled,
  className,
  ...restProps
}: ButtonProps) {
  return (
    <button
      className={`
        ${className}
        ${padding && "py-2 px-3"}
        ${disabled ? "opacity-40 cursor-not-allowed pointer-events-none" : ""}
      `}
      disabled={disabled}
      {...restProps}
    >
      {children}
    </button>
  );
}

Button.defaultProps = {
  tabIndex: 0,
  padding: true,
};

export default Button;
