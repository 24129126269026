import { useNavigate } from "react-router-dom";

import { Link } from ".";
import { Brand } from "../Icons";

function NotFound() {
  const navigate = useNavigate();

  return (
    <div
      className="
        px-6
        flex
        py-12
        flex-col
        justify-center
        lg:px-8
      "
    >
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="w-20 mx-auto">
          <Brand size={80} color="fill-green-600" />
        </div>
        <h2
          className="
            mt-5
            flex
            text-2xl
            font-bold
            items-center
            justify-center
          "
        >
          <Link
            text="Lost&Found"
            textClassName="text-2xl"
            onClick={() => navigate("/")}
          />
        </h2>
      </div>

      <div className="mt-10 text-center sm:mx-auto sm:w-full sm:max-w-sm">
        <h1 className="text-5xl">Page Not Found.</h1>
      </div>
    </div>
  );
}

export default NotFound;
