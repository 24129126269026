import { useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Brand } from "../Icons";

import { Api, StorePersist } from "../../services";
import { INITIAL_USER, useUserContext } from "../../providers/UserProvider";

function Logout() {
	const navigate = useNavigate();
	const { state } = useLocation();

	const logoutRef = useRef<boolean>(false);

	const { handleSetUser } = useUserContext();

	useEffect(() => {
		const logout = async () => {
			logoutRef.current = true;
			StorePersist.clearState();
			handleSetUser({ user: INITIAL_USER, ready: false });

			await Api.logout();
			const next = state?.next || null;
			navigate("/auth/login", next ? { state: { next } } : {});
			if (next)
				StorePersist.saveState({ next }, StorePersist.Items.REDIRECT_STATE);
		};

		if (!logoutRef.current) logout();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div
			className="
        px-6
        flex
        py-12
        flex-col
        justify-center
        lg:px-8
      "
		>
			<div className="sm:mx-auto sm:w-full sm:max-w-sm">
				<div className="w-20 mx-auto">
					<Brand size={80} color="fill-green-600" />
				</div>
				<h2
					className="
            mt-5
            text-2xl
            font-bold
            leading-9
            text-center
            text-black
            tracking-tight
          "
				>
					Logging out...
				</h2>
			</div>
		</div>
	);
}

export default Logout;
