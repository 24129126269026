import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { Button, Form, ModalProps, ModalType } from "../../../Common";

import { formatPhoneNumber } from "../Profile";
import { Api, StorePersist } from "../../../../services";
import { useUserContext } from "../../../../providers/UserProvider";

type UpdateProfileModalProps = {
	onCloseModal: ModalProps["onCloseModal"];
};

export type PatchUserInputs = {
	email: string;
	first_name: string;
	last_name: string;
	phone_number: string;
	phone_number_alt: string;
};

function UpdateProfileModal({ onCloseModal }: UpdateProfileModalProps) {
	const formId = "update-profile-form";
	const { user, handleSetUser } = useUserContext();

	const [error, setError] = useState<{ msg: string; generic?: boolean }>({
		msg: "",
	});

	const { watch, handleSubmit, control, formState } = useForm<PatchUserInputs>({
		mode: "onChange",
		defaultValues: {
			email: user.email,
			last_name: user.lastName,
			first_name: user.firstName,
			phone_number: user.phoneNumber ?? "",
			phone_number_alt: user.phoneNumberAlt ?? "",
		},
	});

	const watchForm = watch(["phone_number", "phone_number_alt"]);

	const onSubmit: SubmitHandler<PatchUserInputs> = async (form) => {
		const { token } = StorePersist.loadState(StorePersist.Items.AUTH_STATE);

		try {
			const { ok, user, error } = await Api.patchUser(form);

			if (ok && user) {
				StorePersist.saveState({ user, token }, StorePersist.Items.AUTH_STATE);
				handleSetUser({
					ready: true,
					user: {
						id: user.id.toString(),
						email: user.email,
						firstName: user.first_name,
						lastName: user.last_name,
						phoneNumber: user.phone_number,
						phoneNumberAlt: user.phone_number_alt,
					},
				});

				onCloseModal({ type: ModalType.UPDATE_PROFILE, data: null });
			} else setError({ msg: error! });
		} catch (e) {
			const error = e as Error;
			setError({ msg: error.message, generic: true });
		}
	};

	const disabledSubmitButton =
		!formState.isValid && "opacity-25 cursor-not-allowed pointer-events-none";

	const transformPhoneInput = {
		input: (value: string) => {
			if (isNaN(Number(value))) return watchForm[0];

			if (value !== "") {
				let newValue = value;
				newValue = formatPhoneNumber(newValue)!;
				return newValue ? newValue : value;
			}

			return "";
		},
		output: (event: React.ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value.replace(/\D/g, "");

			if (isNaN(Number(value))) return watchForm[0];
			return value;
		},
	};

	return (
		<Form id={formId} onSubmit={handleSubmit(onSubmit)}>
			<div
				className="
          text-sm
          overflow-scroll
          max-h-modal-container
          md:text-base
        "
			>
				<Form.Text
					required={true}
					name="first_name"
					control={control}
					label="First Name"
					id="first-name-input"
					errors={formState.errors}
					rules={{
						required: {
							value: true,
							message: "First Name is required.",
						},
					}}
				/>
				<Form.ErrorMessage errors={formState.errors} name="first_name" />
				<Form.Text
					required={true}
					name="last_name"
					control={control}
					label="Last Name"
					id="last-name-input"
					errors={formState.errors}
					rules={{
						required: {
							value: true,
							message: "Last Name is required.",
						},
					}}
				/>
				<Form.ErrorMessage errors={formState.errors} name="last_name" />

				<h3 className="my-3 text-xl leading-6 text-black font-semibold">
					Contact Info
				</h3>
				<Form.Text
					name="email"
					label="Email"
					disabled={true}
					id="email-input"
					control={control}
					errors={formState.errors}
				/>
				<Form.ErrorMessage errors={formState.errors} name="email" />
				<Form.Text
					required={true}
					control={control}
					name="phone_number"
					id="phone-number-input"
					placeholder="17065554433"
					errors={formState.errors}
					transform={transformPhoneInput}
					label="Phone Number (Use country code)"
					rules={{
						maxLength: {
							value: 13,
							message: "Phone Number is too long.",
						},
						pattern: {
							value:
								/\+?\d{1,3}?\s*\(?-*\.*(\d{3})\)?\.*-*\s*(\d{3})\.*-*\s*(\d{4})$/,
							message: "Phone Number is invalid.",
						},
						required: {
							value: true,
							message: "Phone Number is required.",
						},
					}}
				/>
				<Form.ErrorMessage errors={formState.errors} name="phone_number" />
				<Form.Text
					required={true}
					control={control}
					name="phone_number_alt"
					placeholder="17065554433"
					errors={formState.errors}
					id="phone-number-alt-input"
					transform={transformPhoneInput}
					label="Alternative Number (Use country code)"
					rules={{
						maxLength: {
							value: 13,
							message: "Alternative Number is too long.",
						},
						pattern: {
							value:
								/\+?\d{1,3}?\s*\(?-*\.*(\d{3})\)?\.*-*\s*(\d{3})\.*-*\s*(\d{4})$/,
							message: "Alternative Number is invalid.",
						},
						required: {
							value: true,
							message: "Alternative Number is required.",
						},
						validate: {
							verify: (v: string) =>
								v !== watchForm[0] || "Alternative Number should be different.",
						},
					}}
				/>
				<Form.ErrorMessage errors={formState.errors} name="phone_number_alt" />

				<div className="mt-1 text-sm text-center font-semibold text-red-600">
					{error.msg !== "" && (
						<>
							{error.generic && (
								<p>Something went wrong, please try again later!</p>
							)}
							<p>{error.msg}</p>
						</>
					)}
				</div>
			</div>

			<div className="mt-5 sm:flex sm:flex-row-reverse">
				<Button
					form={formId}
					type="submit"
					value="submit"
					id="create-item-submit-button"
					disabled={!formState.isValid}
					className={`
            flex
            w-full
            border-2
            leading-6
            shadow-sm
            rounded-md
            text-white
            font-semibold
            justify-center
            bg-green-600
            border-green-600
            hover:bg-green-500
            ${disabledSubmitButton}
          `}
				>
					Update Profile
				</Button>
			</div>
		</Form>
	);
}

export default UpdateProfileModal;
