import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

import { Form, Button } from "../Common";

import { Brand } from "../Icons";

import { Api } from "../../services";

export type ResetPasswordInputs = {
  email: string;
};

function ResetPassword() {
  const formId = "reset-password-form";

  const location = useLocation();

  const [success, setSuccess] = useState<{ msg: string }>({ msg: "" });
  const [error, setError] = useState<{ msg: string; generic?: boolean }>({
    msg: "",
  });

  const { handleSubmit, control, formState } = useForm<ResetPasswordInputs>({
    mode: "onChange",
    defaultValues: {
      email: location.state?.email || "",
    },
  });

  const onSubmit: SubmitHandler<ResetPasswordInputs> = async (form) => {
    setError({ msg: "" });
    try {
      const response = await Api.resetPassword(form);
      if (response.ok)
        setSuccess({
          msg: "A password reset e-mail has been sent to your email address with all the information to reset your password.",
        });
      else setError({ msg: response.error! });
    } catch (e) {
      const error = e as Error;
      setError({ msg: error.message, generic: true });
    }
  };

  const disabledSubmitButton =
    !formState.isValid && "opacity-25 cursor-not-allowed pointer-events-none";

  return (
    <div
      className="
        px-6
        flex
        py-12
        flex-col
        justify-center
        lg:px-8
      "
    >
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="w-20 mx-auto">
          <Brand size={80} color="fill-green-600" />
        </div>
        <h2
          className="
            mt-5
            text-2xl
            font-bold
            leading-9
            text-center
            text-black
            tracking-tight
          "
        >
          Reset password to your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <Form.Text
            name="email"
            label="email"
            id="email-input"
            required={true}
            control={control}
            errors={formState.errors}
            rules={{
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Enter a valid email.",
              },
              required: { value: true, message: "Email is required." },
            }}
          />
          <Form.ErrorMessage errors={formState.errors} name="email" />

          <div className="mt-1 text-sm text-center font-semibold">
            {success && <p>{success.msg}</p>}
            {error && (
              <div className="text-red-600">
                {error.generic && (
                  <p>Something went wrong, please try again later!</p>
                )}
                <p>{error.msg}</p>
              </div>
            )}
          </div>

          <Button
            form={formId}
            type="submit"
            value="submit"
            id="login-submit-button"
            disabled={!formState.isValid}
            className={`
              flex
              mt-10
              w-full
              border-2
              leading-6
              shadow-sm
              rounded-md
              text-white
              font-semibold
              justify-center
              bg-green-600
              border-green-600
              hover:bg-green-500
              ${disabledSubmitButton}
            `}
          >
            Reset Password
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default ResetPassword;
