import {
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useReducer,
} from "react";

export const INITIAL_USER = {
	email: "",
	firstName: "",
	id: "",
	lastName: "",
	phoneNumber: "",
	phoneNumberAlt: "",
};

enum UserActionTypes {
	SET_USER = "SET_USER",
}

type UserProviderProps = {
	children: ReactNode;
};

type User = {
	email: string;
	firstName: string;
	id: string;
	lastName: string;
	phoneNumber?: string;
	phoneNumberAlt?: string;
};

type UserStateType = {
	ready: boolean;
	user: User;
};

type UserReducerActionType = {
	ready: UserStateType["ready"];
	type: keyof typeof UserActionTypes;
	user: UserStateType["user"];
};

type UserContextType = {
	handleSetUser: (props: UserStateType) => void;
	isAuthenticated: UserStateType["ready"];
	user: UserStateType["user"];
};

const UserContext = createContext<UserContextType>({
	handleSetUser: (props: UserStateType) => {},
	isAuthenticated: false,
	user: INITIAL_USER,
});

function userReducer(state: UserStateType, action: UserReducerActionType) {
	switch (action.type) {
		case UserActionTypes.SET_USER:
			return { ...state, ready: action.ready, user: action.user };
		default:
			return state;
	}
}

function UserProvider({ children }: UserProviderProps) {
	const [userState, dispatchUserState] = useReducer(userReducer, {
		user: INITIAL_USER,
		ready: false,
	});

	const handleSetUser = useCallback(({ user, ready }: UserStateType) => {
		dispatchUserState({ type: UserActionTypes.SET_USER, user, ready });
	}, []);

	return (
		<UserContext.Provider
			value={{
				handleSetUser,
				isAuthenticated: userState.ready,
				user: userState.user,
			}}
		>
			{children}
		</UserContext.Provider>
	);
}

export const useUserContext = () => {
	const context = useContext(UserContext);

	return context;
};

export default UserProvider;
