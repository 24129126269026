import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Brand } from "../Icons";

import { Api } from "../../services";

function VerifyEmail() {
  const { key } = useParams();
  const navigate = useNavigate();

  const verifyRef = useRef<boolean>(false);

  const [error, setError] = useState<{ msg: string; generic?: boolean }>({
    msg: "",
  });

  useEffect(() => {
    const handleVerifyEmail = async () => {
      if (verifyRef.current) return;

      verifyRef.current = true;
      setError({ msg: "" });
      try {
        const response = await Api.verifyEmail({ key: key! });

        if (response.ok) navigate("/auth/login");
        else setError({ msg: response.error! });
      } catch (e) {
        const error = e as Error;
        console.error(error);
        setError({ msg: error.message, generic: true });
      }
    };

    if (key) handleVerifyEmail();
    else navigate("/");
  }, [key, navigate]);

  return (
    <div
      className="
        px-6
        flex
        py-12
        flex-col
        justify-center
        lg:px-8
      "
    >
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="w-20 mx-auto">
          <Brand size={80} color="fill-green-600" />
        </div>
        <h2
          className="
            mt-5
            text-2xl
            font-bold
            leading-9
            text-center
            text-black
            tracking-tight
          "
        >
          Verifying email...
        </h2>
      </div>

      <div className="mt-10 text-center sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="mt-1 text-sm text-center font-semibold text-red-600">
          {error && (
            <>
              {error.generic && (
                <p>Something went wrong, please try again later!</p>
              )}
              <p>{error.msg}</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default VerifyEmail;
