import { useCallback } from "react";

import { Brand, Close } from "../Icons";

import { UpdateProfileModal } from "../Pages/Profile/Modals";
import {
	AddQrModal,
	AddItemModal,
	DeleteItemModal,
} from "../Pages/QrCode/Modals";

export enum ModalType {
	"ADD_QR" = "ADD_QR",
	"ADD_ITEM" = "ADD_ITEM",
	"DELETE_ITEM" = "DELETE_ITEM",
	"UPDATE_PROFILE" = "UPDATE_PROFILE",
}

type GenericObject = { title?: string; [key: string]: any } | null;
type ModalDataType = {
	data: GenericObject;
	type: "" | keyof typeof ModalType;
};
export type ModalProps = {
	show: boolean;
	modalData: ModalDataType;
	onCloseModal: ({ type, data }: ModalDataType) => void;
};

function Modal({ show, modalData, onCloseModal }: ModalProps) {
	const getModalContent = useCallback(() => {
		switch (modalData.type) {
			case ModalType.ADD_QR:
				return (
					<AddQrModal onCloseModal={onCloseModal} code={modalData.data?.code} />
				);
			case ModalType.ADD_ITEM:
				return <AddItemModal onCloseModal={onCloseModal} />;
			case ModalType.DELETE_ITEM:
				return (
					<DeleteItemModal
						onCloseModal={onCloseModal}
						item={modalData.data?.item}
					/>
				);
			case ModalType.UPDATE_PROFILE:
				return <UpdateProfileModal onCloseModal={onCloseModal} />;

			default:
				return null;
		}
	}, [modalData, onCloseModal]);

	if (!show) return null;

	return (
		<div
			id="id01"
			role="dialog"
			aria-modal="true"
			className="z-10 relative"
			aria-labelledby="modal-title"
		>
			<div className="fixed inset-0 bg-green-600 bg-opacity-50 transition-opacity"></div>

			<div className="fixed inset-0 z-10 overflow-hidden">
				<div
					className="
            p-4
            flex
            min-h-full
            text-center
            items-center
            justify-center
            sm:p-0
        "
				>
					<div
						className="
              w-full
              relative
              transform
              text-left
              shadow-xl
              bg-white
              rounded-lg
              transition-all
              overflow-hidden
              sm:my-8
              sm:max-w-lg
            "
					>
						<div className="p-4 bg-white sm:p-6">
							<div
								className="
                  flex
                  flex-row
                  space-x-2
                  items-center
                  justify-between
                "
							>
								<div
									className="
                    flex
                    flex-row
                    space-x-2
                    items-center
                  "
								>
									<div className="h-5 w-5">
										<Brand size={20} color="fill-green-600" />
									</div>
									<h3
										id="modal-title"
										className="text-xl leading-6 text-black font-semibold"
									>
										{modalData.data?.title}
									</h3>
								</div>
								<div
									onClick={() => onCloseModal({ type: "", data: null })}
									className="
                    h-8
                    w-8
                    ml-auto
                    text-black
                    cursor-pointer
                    hover:text-green-600
                  "
								>
									<Close width={32} height={32} color="fill-current" />
								</div>
							</div>
							<div className="mt-5 text-center sm:text-left">
								{getModalContent()}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Modal;
